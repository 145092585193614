import {
  ProfileExtraData,
  ProfileSummary,
  UpdateDatosPersonales,
  UpdateDatosContactoType,
  UpdateDocumentoPayload,
  DatosPersonales,
  VisualizacionesCV,
} from './commons'
import {
  DeleteDiscapacidad,
  DeleteDiscapacidadReset,
  DeleteDiscapacidadSuccess,
  DeleteDiscapacidadError,
  AddDiscapacidad,
  AddDiscapacidadReset,
  AddDiscapacidadSuccess,
  AddDiscapacidadError,
  GetDiscapacidad,
  GetDiscapacidadReset,
  GetDiscapacidadSuccess,
  GetDiscapacidadError,
} from './curriculum/discapacidad'
import {
  GetCvAdjunto,
  GetCvAdjuntoReset,
  GetCvAdjuntoSuccess,
  GetCvAdjuntoError,
  DeleteCvAdjunto,
  DeleteCvAdjuntoReset,
  DeleteCvAdjuntoSuccess,
  DeleteCvAdjuntoError,
  AddCvAdjunto,
  AddCvAdjuntoReset,
  AddCvAdjuntoSuccess,
  AddCvAdjuntoError,
} from './curriculum/cv-adjunto'
import {
  GetVideoPresentacion,
  GetVideoPresentacionReset,
  GetVideoPresentacionSuccess,
  GetVideoPresentacionError,
  AddVideoPresentacion,
  AddVideoPresentacionReset,
  AddVideoPresentacionSuccess,
  AddVideoPresentacionError,
  DeleteVideoPresentacion,
  DeleteVideoPresentacionReset,
  DeleteVideoPresentacionSuccess,
  DeleteVideoPresentacionError,
  GetVideoPresentacionPreview,
  GetVideoPresentacionPreviewReset,
  GetVideoPresentacionPreviewSuccess,
  GetVideoPresentacionPreviewError,
} from './curriculum/video-presentacion'
import { ShowSnackbar, HideSnackbar } from './curriculum/snackbar'
import {
  AddOrUpdateEstudio,
  AddOrUpdateEstudioSuccess,
  AddOrUpdateEstudioError,
  DeleteEstudio,
  DeleteEstudioSuccess,
  DeleteEstudioError,
  DeleteReferenciaEstudio,
  DeleteReferenciaEstudioSuccess,
  DeleteReferenciaEstudioError,
  UpdateReferenciaEstudio,
  UpdateReferenciaEstudioSuccess,
  UpdateReferenciaEstudioError,
  AddOrUpdateEstudioReset,
  DeleteEstudioReset,
  DeleteReferenciaEstudioReset,
  UpdateReferenciaEstudioReset,
  AddEstudios,
  AddEstudiosError,
  AddEstudiosReset,
  AddEstudiosSuccess,
  SinEstudios,
  SinEstudiosSuccess,
  SinEstudiosError,
  SinEstudiosReset,
  DeleteCertificadoEstudio,
  DeleteCertificadoEstudioSuccess,
  DeleteCertificadoEstudioError,
  UpdateCertificadoEstudio,
  UpdateCertificadoEstudioSuccess,
  UpdateCertificadoEstudioError,
  UpdateCertificadoEstudioReset,
} from './curriculum/estudios'
import {
  UpdateIdiomaReset,
  DeleteIdiomaReset,
  DeleteIdioma,
  DeleteIdiomaSuccess,
  DeleteIdiomaError,
  UpdateIdioma,
  UpdateIdiomaSuccess,
  UpdateIdiomaError,
  AddIdiomas,
  AddIdiomasError,
  AddIdiomasReset,
  AddIdiomasSuccess,
} from './curriculum/idiomas'
import {
  DeleteExperiencia,
  DeleteExperienciaSuccess,
  DeleteExperienciaError,
  DeleteExperienciaReset,
  DeleteReferenciaExperiencia,
  DeleteReferenciaExperienciaSuccess,
  DeleteReferenciaExperienciaError,
  DeleteReferenciaExperienciaReset,
  UpdateReferenciaLaboral,
  UpdateReferenciaLaboralSuccess,
  UpdateReferenciaLaboralReset,
  UpdateReferenciaLaboralError,
  UpdateExperiencia,
  UpdateExperienciaSuccess,
  UpdateExperienciaError,
  UpdateExperienciaReset,
  PrimerEmpleo,
  PrimerEmpleoSuccess,
  PrimerEmpleoError,
  PrimerEmpleoReset,
  UpdateExperiencias,
  UpdateExperienciasSuccess,
  UpdateExperienciasError,
  UpdateExperienciasReset,
} from './curriculum/experiencia-laboral'
import {
  UpdateSkills,
  UpdateSkillsSuccess,
  UpdateSkillsError,
  UpdateSkillsReset,
  DeleteSkills,
  DeleteSkillsSuccess,
  DeleteSkillsError,
  DeleteSkillsReset,
  ReplaceSkills,
  ReplaceSkillsSuccess,
  ReplaceSkillsError,
  ReplaceSkillsReset,
} from './curriculum/skills'
import {
  GetPreferenciaSalarial,
  GetPreferenciaSalarialSuccess,
  GetPreferenciaSalarialError,
  UpdatePreferenciaSalarial,
  UpdatePreferenciaSalarialSuccess,
  UpdatePreferenciaSalarialError,
  UpdatePreferenciaSalarialReset,
  DeletePreferenciaSalarial,
  DeletePreferenciaSalarialSuccess,
  DeletePreferenciaSalarialError,
  DeletePreferenciaSalarialReset,
} from './curriculum/preferencia-salarial'
import {
  UpdateObjetivoLaboral,
  UpdateObjetivoLaboralSuccess,
  UpdateObjetivoLaboralError,
  UpdateObjetivoLaboralReset,
  DeleteObjetivoLaboral,
  DeleteObjetivoLaboralSuccess,
  DeleteObjetivoLaboralError,
  DeleteObjetivoLaboralReset,
} from './curriculum/objetivo-laboral'
import {
  UpdatePda,
  UpdatePdaSuccess,
  UpdatePdaError,
  UpdatePdaReset,
  GetLinkReporte,
  GetLinkReporteError,
  GetLinkReporteReset,
  GetLinkReporteSuccess,
} from './curriculum/pda'
import { TieneCV, TieneCVError, TieneCVReset, TieneCVSuccess } from './curriculum/tiene-cv'
import {
  UpdateDatosPersonalesOnbordingType,
  UPDATE_DATOS_PERSONALES_ONBORDING,
  UPDATE_DATOS_PERSONALES_ONBORDING_ERROR,
  UPDATE_DATOS_PERSONALES_ONBORDING_SUCCESS,
} from './curriculum/datos-personales-onbording'
import {
  ActualizarFechaCV,
  ActualizarFechaCVError,
  ActualizarFechaCVReset,
  ActualizarFechaCVSuccess,
} from './curriculum/fecha-cv'
import {
  GetCertificadoAdjunto,
  GetCertificadoAdjuntoError,
  GetCertificadoAdjuntoReset,
  GetCertificadoAdjuntoSuccess,
} from './curriculum/certificado-adjunto'

export const GET_APPLICANT = 'GET_APPLICANT'
export const GET_APPLICANT_SUCCESS = 'GET_APPLICANT_SUCCESS'
export const GET_APPLICANT_ERROR = 'GET_APPLICANT_ERROR'

export const GET_APPLICANT_SUMMARY = 'GET_APPLICANT_SUMMARY'
export const GET_APPLICANT_SUMMARY_SUCCESS = 'GET_APPLICANT_SUMMARY_SUCCESS'
export const GET_APPLICANT_SUMMARY_ERROR = 'GET_APPLICANT_SUMMARY_ERROR'

export const GET_DATOS_PERSONALES = 'GET_DATOS_PERSONALES'
export const GET_DATOS_PERSONALES_SUCCESS = 'GET_DATOS_PERSONALES_SUCCESS'
export const GET_DATOS_PERSONALES_ERROR = 'GET_DATOS_PERSONALES_ERROR'

export const GET_CURRICULUM = 'GET_CURRICULUM'
export const GET_CURRICULUM_SUCCESS = 'GET_CURRICULUM_SUCCESS'
export const GET_CURRICULUM_ERROR = 'GET_CURRICULUM_ERROR'

export const ADD_DATOS_PERSONALES = 'ADD_DATOS_PERSONALES'
export const ADD_DATOS_PERSONALES_RESET = 'ADD_DATOS_PERSONALES_RESET'
export const ADD_DATOS_PERSONALES_SUCCESS = 'ADD_DATOS_PERSONALES_SUCCESS'
export const ADD_DATOS_PERSONALES_ERROR = 'ADD_DATOS_PERSONALES_ERROR'

export const UPDATE_IMAGE_PROFILE = 'UPDATE_IMAGE_PROFILE'
export const UPDATE_IMAGE_PROFILE_SUCCESS = 'UPDATE_IMAGE_PROFILE_SUCCESS'
export const UPDATE_IMAGE_PROFILE_ERROR = 'UPDATE_IMAGE_PROFILE_ERROR'
export const UPDATE_IMAGE_PROFILE_RESET = 'UPDATE_IMAGE_PROFILE_RESET'

export const UPDATE_DATOS_CONTACTO = 'UPDATE_DATOS_CONTACTO'
export const UPDATE_DATOS_CONTACTO_RESET = 'UPDATE_DATOS_CONTACTO_RESET'
export const UPDATE_DATOS_CONTACTO_SUCCESS = 'UPDATE_DATOS_CONTACTO_SUCCESS'
export const UPDATE_DATOS_CONTACTO_ERROR = 'UPDATE_DATOS_CONTACTO_ERROR'
export const RESET_APPLICANT = 'RESET_APPLICANT'

export const UPDATE_DOCUMENTO = 'UPDATE_DOCUMENTO'
export const UPDATE_DOCUMENTO_ERROR = 'UPDATE_DOCUMENTO_ERROR'
export const UPDATE_DOCUMENTO_SUCCESS = 'UPDATE_DOCUMENTO_SUCCESS'

export const GET_VISUALIZACIONES_CV = 'GET_VISUALIZACIONES_CV'
export const GET_VISUALIZACIONES_CV_SUCCESS = 'GET_VISUALIZACIONES_CV_SUCCESS'
export const GET_VISUALIZACIONES_CV_ERROR = 'GET_VISUALIZACIONES_CV_ERROR'

//  Get applicant
export interface GetApplicant {
  type: typeof GET_APPLICANT
  payload: null
}
export interface GetApplicantSuccess {
  type: typeof GET_APPLICANT_SUCCESS
  payload: ProfileExtraData | null
}
export interface GetApplicantError {
  type: typeof GET_APPLICANT_ERROR
  payload: boolean
}

// Get summary
export interface GetApplicantSummary {
  type: typeof GET_APPLICANT_SUMMARY
  payload: null
}
export interface GetApplicantSummarySuccess {
  type: typeof GET_APPLICANT_SUMMARY_SUCCESS
  payload: ProfileSummary | null
}
export interface GetApplicantSummaryError {
  type: typeof GET_APPLICANT_SUMMARY_ERROR
  payload: boolean
}

// Get datos personales
export interface GetDatosPersonales {
  type: typeof GET_DATOS_PERSONALES
  payload: null
}
export interface GetDatosPersonalesSuccess {
  type: typeof GET_DATOS_PERSONALES_SUCCESS
  payload: DatosPersonales | null
}

export interface GetDatosPersonalesError {
  type: typeof GET_DATOS_PERSONALES_ERROR
  payload: boolean
}

// Get curriculum
export interface GetCurriculum {
  type: typeof GET_CURRICULUM
  payload: null
}
export interface GetCurriculumSuccess {
  type: typeof GET_CURRICULUM_SUCCESS
  payload: ProfileExtraData | null
}
export interface GetCurriculumError {
  type: typeof GET_CURRICULUM_ERROR
  payload: boolean
}

// Update datos personales
export interface AddDatosPersonales {
  type: typeof ADD_DATOS_PERSONALES
  payload: UpdateDatosPersonales
}
export interface AddDatosPersonalesReset {
  type: typeof ADD_DATOS_PERSONALES_RESET
  payload: null
}
export interface AddDatosPersonalesSuccess {
  type: typeof ADD_DATOS_PERSONALES_SUCCESS
  payload: UpdateDatosPersonales
}
export interface AddDatosPersonalesError {
  type: typeof ADD_DATOS_PERSONALES_ERROR
  payload: null
}

// Update image profile
export interface UpdateImageProfile {
  type: typeof UPDATE_IMAGE_PROFILE
  payload: string
}
export interface UpdateImageProfileSuccess {
  type: typeof UPDATE_IMAGE_PROFILE_SUCCESS
  payload: string
}
export interface UpdateImageProfileError {
  type: typeof UPDATE_IMAGE_PROFILE_ERROR
  payload: null
}
export interface UpdateImageProfileReset {
  type: typeof UPDATE_IMAGE_PROFILE_RESET
  payload: null
}

// Update Datos de contacto
export interface UpdateDatosContacto {
  type: typeof UPDATE_DATOS_CONTACTO
  payload: UpdateDatosContactoType
}
export interface UpdateDatosContactoReset {
  type: typeof UPDATE_DATOS_CONTACTO_RESET
  payload: null
}
export interface UpdateDatosContactoSuccess {
  type: typeof UPDATE_DATOS_CONTACTO_SUCCESS
  payload: null
}
export interface UpdateDatosContactoError {
  type: typeof UPDATE_DATOS_CONTACTO_ERROR
  payload: null
}

// Update documento
export interface UpdateDocumento {
  type: typeof UPDATE_DOCUMENTO
  payload: UpdateDocumentoPayload
}
export interface UpdateDocumentoError {
  type: typeof UPDATE_DOCUMENTO_ERROR
  payload: boolean
}
export interface UpdateDocumentoSuccess {
  type: typeof UPDATE_DOCUMENTO_SUCCESS
  payload: UpdateDocumentoPayload
}
// Step Datos de contacto
export interface UpdateDatosPersonalesOnbording {
  type: typeof UPDATE_DATOS_PERSONALES_ONBORDING
  payload: UpdateDatosPersonalesOnbordingType
}

export interface UpdateDatosPersonalesOnbordingSuccess {
  type: typeof UPDATE_DATOS_PERSONALES_ONBORDING_SUCCESS
  payload: null
}
export interface UpdateDatosPersonalesOnbordingError {
  type: typeof UPDATE_DATOS_PERSONALES_ONBORDING_ERROR
  payload: null
}

// Visualizaciones de cv
export interface GetVisualizacionesCV {
  type: typeof GET_VISUALIZACIONES_CV
}

export interface GetVisualizacionesCVSuccess {
  type: typeof GET_VISUALIZACIONES_CV_SUCCESS
  payload: VisualizacionesCV
}

export interface GetVisualizacionesCVError {
  type: typeof GET_VISUALIZACIONES_CV_ERROR
}

// Reset applicant
export interface ResetApplicant {
  type: typeof RESET_APPLICANT
  payload: null
}

export type ApplicantActions =
  | GetApplicant
  | GetApplicantSuccess
  | GetApplicantError
  | GetApplicantSummary
  | GetApplicantSummarySuccess
  | GetApplicantSummaryError
  | GetDatosPersonales
  | GetDatosPersonalesSuccess
  | GetDatosPersonalesError
  | GetCurriculum
  | GetCurriculumSuccess
  | GetCurriculumError
  | AddDatosPersonales
  | AddDatosPersonalesReset
  | AddDatosPersonalesSuccess
  | AddDatosPersonalesError
  | UpdateDatosPersonalesOnbording
  | UpdateDatosPersonalesOnbordingSuccess
  | UpdateDatosPersonalesOnbordingError
  | UpdateImageProfile
  | UpdateImageProfileSuccess
  | UpdateImageProfileError
  | UpdateImageProfileReset
  | UpdateDatosContacto
  | UpdateDatosContactoReset
  | UpdateDatosContactoSuccess
  | UpdateDatosContactoError
  | ShowSnackbar
  | HideSnackbar
  | SinEstudios
  | SinEstudiosSuccess
  | SinEstudiosError
  | SinEstudiosReset
  | AddOrUpdateEstudio
  | AddOrUpdateEstudioSuccess
  | AddOrUpdateEstudioError
  | AddOrUpdateEstudioReset
  | AddEstudios
  | AddEstudiosSuccess
  | AddEstudiosError
  | AddEstudiosReset
  | DeleteEstudio
  | DeleteEstudioSuccess
  | DeleteEstudioError
  | DeleteReferenciaEstudio
  | DeleteReferenciaEstudioSuccess
  | DeleteReferenciaEstudioError
  | UpdateReferenciaEstudio
  | UpdateReferenciaEstudioSuccess
  | UpdateReferenciaEstudioError
  | DeleteCertificadoEstudio
  | DeleteCertificadoEstudioSuccess
  | DeleteCertificadoEstudioError
  | UpdateCertificadoEstudio
  | UpdateCertificadoEstudioSuccess
  | UpdateCertificadoEstudioError
  | UpdateCertificadoEstudioReset
  | DeleteIdioma
  | DeleteIdiomaSuccess
  | DeleteIdiomaError
  | UpdateIdioma
  | UpdateIdiomaSuccess
  | UpdateIdiomaError
  | AddIdiomas
  | AddIdiomasSuccess
  | AddIdiomasError
  | AddIdiomasReset
  | DeleteDiscapacidad
  | DeleteDiscapacidadReset
  | DeleteDiscapacidadSuccess
  | DeleteDiscapacidadError
  | AddDiscapacidad
  | AddDiscapacidadReset
  | AddDiscapacidadSuccess
  | AddDiscapacidadError
  | GetDiscapacidad
  | GetDiscapacidadReset
  | GetDiscapacidadSuccess
  | GetDiscapacidadError
  | GetPreferenciaSalarial
  | GetPreferenciaSalarialSuccess
  | GetPreferenciaSalarialError
  | UpdatePreferenciaSalarial
  | UpdatePreferenciaSalarialSuccess
  | UpdatePreferenciaSalarialError
  | UpdatePreferenciaSalarialReset
  | DeletePreferenciaSalarial
  | DeletePreferenciaSalarialSuccess
  | DeletePreferenciaSalarialError
  | DeletePreferenciaSalarialReset
  | UpdateIdiomaReset
  | DeleteEstudioReset
  | DeleteReferenciaEstudioReset
  | DeleteIdiomaReset
  | UpdateReferenciaEstudioReset
  | UpdateObjetivoLaboral
  | UpdateObjetivoLaboralSuccess
  | UpdateObjetivoLaboralError
  | UpdateObjetivoLaboralReset
  | DeleteObjetivoLaboral
  | DeleteObjetivoLaboralSuccess
  | DeleteObjetivoLaboralError
  | DeleteObjetivoLaboralReset
  | UpdateSkills
  | UpdateSkillsSuccess
  | UpdateSkillsError
  | UpdateSkillsReset
  | ReplaceSkills
  | ReplaceSkillsSuccess
  | ReplaceSkillsError
  | ReplaceSkillsReset
  | DeleteSkills
  | DeleteSkillsSuccess
  | DeleteSkillsError
  | DeleteSkillsReset
  | DeleteExperiencia
  | DeleteExperienciaSuccess
  | DeleteExperienciaError
  | DeleteExperienciaReset
  | DeleteReferenciaExperiencia
  | DeleteReferenciaExperienciaSuccess
  | DeleteReferenciaExperienciaError
  | DeleteReferenciaExperienciaReset
  | GetCvAdjunto
  | GetCvAdjuntoReset
  | GetCvAdjuntoSuccess
  | GetCvAdjuntoError
  | GetCertificadoAdjunto
  | GetCertificadoAdjuntoReset
  | GetCertificadoAdjuntoSuccess
  | GetCertificadoAdjuntoError
  | DeleteCvAdjunto
  | DeleteCvAdjuntoReset
  | DeleteCvAdjuntoSuccess
  | DeleteCvAdjuntoError
  | AddCvAdjunto
  | AddCvAdjuntoReset
  | AddCvAdjuntoSuccess
  | AddCvAdjuntoError
  | GetVideoPresentacion
  | GetVideoPresentacionReset
  | GetVideoPresentacionSuccess
  | GetVideoPresentacionError
  | GetVideoPresentacionPreview
  | GetVideoPresentacionPreviewReset
  | GetVideoPresentacionPreviewSuccess
  | GetVideoPresentacionPreviewError
  | AddVideoPresentacion
  | AddVideoPresentacionReset
  | AddVideoPresentacionSuccess
  | AddVideoPresentacionError
  | DeleteVideoPresentacion
  | DeleteVideoPresentacionReset
  | DeleteVideoPresentacionSuccess
  | DeleteVideoPresentacionError
  | UpdatePda
  | UpdatePdaSuccess
  | UpdatePdaError
  | UpdatePdaReset
  | GetLinkReporte
  | GetLinkReporteSuccess
  | GetLinkReporteError
  | GetLinkReporteReset
  | UpdateReferenciaLaboral
  | UpdateReferenciaLaboralSuccess
  | UpdateReferenciaLaboralReset
  | UpdateReferenciaLaboralError
  | UpdateExperiencia
  | UpdateExperienciaSuccess
  | UpdateExperienciaError
  | UpdateExperienciaReset
  | UpdateExperiencias
  | UpdateExperienciasSuccess
  | UpdateExperienciasError
  | UpdateExperienciasReset
  | ResetApplicant
  | PrimerEmpleo
  | PrimerEmpleoSuccess
  | PrimerEmpleoError
  | PrimerEmpleoReset
  | TieneCV
  | TieneCVSuccess
  | TieneCVError
  | TieneCVReset
  | UpdateDocumento
  | UpdateDocumentoError
  | UpdateDocumentoSuccess
  | ActualizarFechaCV
  | ActualizarFechaCVSuccess
  | ActualizarFechaCVError
  | ActualizarFechaCVReset
  | GetVisualizacionesCV
  | GetVisualizacionesCVError
  | GetVisualizacionesCVSuccess
