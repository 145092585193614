import uuid from 'uuid'
import { post, Request, Post } from '@navent-jobs/config'
import LoggerService from './logger-service'
import Applicant from './applicant'
import suscripcionService from './suscripcion'
import { SignInGoogleRequestDto, SignInLinkedinRequestDto, SignInLinkedinResponseDto } from '../redux/types/sign-in'
import { getCookieData } from '../utils/cookies'

const numberDays = 89
const numberHours = 24
const numberMinutes = 3600
const numberSeconds = 1000

export class ErrorSignIn extends Error {}

interface ErrorLogRequest {
  token: string
  message: string
  url: string
  query: string
  trace?: string
}

interface LoginDataType {
  access_token: string
  refresh_token: string
  expires_in: string
  token_type: string
  refresh_token_exp: string
}
export class AuthenticationService {
  public static loginPost = async (username, password): Promise<LoginDataType | null> => {
    try {
      const response = await post('/api/autentificacion/login-encrypted/', {
        username,
        password,
      })
      return response
    } catch (error) {
      throw new ErrorSignIn(`No se pudo loguear`)
    }
  }

  public static logoutPost = async (): Promise<void> => {
    try {
      await post('/api/autentificacion/logout/', {})
    } catch (error) {
    }
  }

  public static autologinPost = async (tokenAuto: string, restricted: boolean): Promise<LoginDataType | null> => {
    try {
      const response = await post('/api/autentificacion/autologin-encrypted/', {
        tokenAuto,
      })
      if (restricted) {
        const oneMonth = new Date(Date.now() + numberDays * numberHours * numberMinutes * numberSeconds).toUTCString()
        const expirationDate = `expires=${oneMonth};`
        document.cookie = `loginRestringido=true; ${expirationDate} path=/`
      }
      return response
    } catch (error) {
      // Es esperable que los tokens expiren y BFF devuelva status 401
      return null
    }
  }

  public writeGrailsCookieOnDocument = (name: string, value: string, expires: string): void => {
    document.cookie = `${name}=${value}; expires=${expires}; path=/`
  }

  public writeGrailsUserSessionIdCookieOnDocument = (): string => {
    const nextMonth = new Date(Date.now() + numberDays * numberHours * numberMinutes * numberSeconds).toUTCString()
    const userSessionId = getCookieData('user_session_id', document.cookie) || uuid.v4()
    this.writeGrailsCookieOnDocument('user_session_id', userSessionId, nextMonth)
    return userSessionId
  }

  public writeGrailsCookiesOnDocument = (data: LoginDataType, keepSession = true): string => {
    const thirtyDays = new Date(Date.now() + numberDays * numberHours * numberMinutes * numberSeconds).toUTCString()
    const expirationDate = keepSession ? `expires=${thirtyDays};` : ''
    document.cookie = `token_auth_postulante=${data.access_token}; ${expirationDate} path=/`
    document.cookie = `refresh_token_auth_postulante=${data.refresh_token}; ${expirationDate} path=/`
    document.cookie = `token_expiration_auth_postulante_milis=${data.expires_in}; ${expirationDate} path=/`
    // document.cookie = `user_session_id=${userSessionId}; expires=${nextMonth}; path=/`
    return this.writeGrailsUserSessionIdCookieOnDocument()
  }

  public loginUser = async ({ keepSession, password, user }) => {
    const loginData = await AuthenticationService.loginPost(user, password)

    const rtoken = getCookieData('loginRestringido', document.cookie)
    if (rtoken) {
      await this.logoutUser()
    }

    if (!loginData) {
      return false
    }

    return this.writeGrailsCookiesOnDocument(loginData, keepSession)
  }

  public loginUserGoogle = async (input: SignInGoogleRequestDto): Promise<boolean> => {
    const rtoken = getCookieData('loginRestringido', document.cookie)
    if (rtoken) {
      await this.logoutUser()
    }

    try {
      const response = await post('/api/autentificacion/login-encrypted/google', { token: input.token })
      this.writeGrailsCookiesOnDocument(response)
      return true
    } catch (error) {
      const axiosError: any = error

      if (axiosError?.response?.status === 404) {
        return false
      }
      throw error
    }
  }

  public loginUserLinkedin = async (input: SignInLinkedinRequestDto): Promise<SignInLinkedinResponseDto> => {
    const rtoken = getCookieData('loginRestringido', document.cookie)
    if (rtoken) {
      await this.logoutUser()
    }

    const response = await post('/api/autentificacion/login-encrypted/linkedin', { code: input.code })
    if (response.token) {
      this.writeGrailsCookiesOnDocument(response.token)
    }
    return response
  }

  public logoutUser = async () => {
    await AuthenticationService.logoutPost()
    const expiredDate = new Date(0).toUTCString()
    document.cookie = `token_auth_postulante= ; expires=${expiredDate}; path=/`
    document.cookie = `refresh_token_auth_postulante= ; expires=${expiredDate}; path=/`
    document.cookie = `token_expiration_auth_postulante_milis= ; expires=${expiredDate}; path=/`
    document.cookie = `loginRestringido=false ; expires=${expiredDate}; path=/`
  }

  public register = async ({
    nombre,
    apellido,
    email,
    password,
    suscripciones,
    documento,
    tipoDocumentoId,
    celularPrefijo,
    celularNumero,
    proteccionDatos,
    token,
    type,
  }) => {
    // Vemos si el registro es x una UTM Campaign
    const utmOrigen = getCookieData('utm_campaign', document.cookie)

    const hasError = { catch: false, type: 'registro' }

    const request = new Request()
    request.path = '/api/autentificacion/register/'
    request.body = {
      email,
      password,
      loginDeTerceros: token ? type : null,
      contextoDeLoginDeTerceros: token ?? null,
      utmOrigen,
    }
    request.method = Post
    await request
      .call()
      .then(async idPostulacion => {
        if (idPostulacion) {
          const session = await this.loginUser({ keepSession: true, user: email, password })
          hasError.catch = !session
        }
      })
      .then(async () => {
        if (!hasError.catch) {
          // si no tuvo error guardo los datos
          await Applicant.UpdateDatosRegistro({
            apellido,
            nombre,
            documento,
            tipoDocumentoId,
            celularPrefijo,
            celularNumero,
          })
          if (proteccionDatos !== null) {
            Applicant.dataProtectionPolicy(proteccionDatos).catch(() => {
              console.error('Falla al registrar politicas de privacidad')
            })
          }
          if (suscripciones === false) {
            suscripcionService.editarSuscripcion({ idCategoria: 1, email: false, push: null }).catch(() => {
              console.error('Falla al registrar suscripcripcion')
            })
          }
          Applicant.putSuscripciones(2).catch(() => {
            console.error('Falla al registrar suscripcripcion')
          })
        } else {
          hasError.type = 'Login'
          throw new Error(`ERROR EN FLUJO DE REGISTRO ${JSON.stringify(hasError)}`)
        }
      })
      .catch(error => {
        hasError.catch = false
        return error
      })

    return hasError.catch
  }

  public async autologin(tokenAuto: string, restricted: boolean) {
    // En primer lugarse hace logout
    await this.logoutUser()

    this.writeGrailsUserSessionIdCookieOnDocument()
    const loginData = await AuthenticationService.autologinPost(tokenAuto, restricted)

    if (!loginData) {
      return false
    }

    this.writeGrailsCookiesOnDocument(loginData, false)
    // store.dispatch(fetchProfileData())
    return true
  }

  public loginCookieExist = () => {
    return getCookieData('token_auth_postulante', document.cookie)
  }

  public loginDataCookieExist = () => {
    return !!getCookieData('token_auth_postulante', document.cookie)
  }
}

const authService = new AuthenticationService()
export default authService
