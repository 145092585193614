import React, { useContext } from 'react'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { ThemeContext } from 'styled-components'

// components
import { LinkJovenesProfesionales } from '../../../../components/Header/components/button-jovenes-profesionales'
import ButtonPremium from '../../../../components/Header/components/button-premium'
import Notifications from '../../../../components/Notifications'

// mixins
import { Text } from '../mixins'
import { CrossSiteDataLayers } from '../../../gtm-events-cross-site'
import i18n from '../../../../i18n'

const linksUserMenu = {
  options: (variant, target = '_self') => ({
    size: 'small',
    variant,
    type: 'link',
    native: false,
    target,
  }),
}

interface MenuItemIconProps {
  iconName: string
}

const MenuItemIcon = ({ iconName }: MenuItemIconProps) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  return <Icon name={iconName} color={colors.grey60} />
}

const allPortalsBM = ['BMAR', 'BMCL', 'BMEC', 'BMMX', 'BMPA', 'BMPE', 'BMVE']
const allPortals = [...allPortalsBM, 'ZJAR']
/**
 * Si un item no posee la prop 'href' por consecuencia se renderiza la prop 'content': string || react element
 * Si un item posee 'content' esta prop tiene más jerarquía que 'href'
 */

export const dataLink = {
  userMenu: {
    miPerfil: {
      href: '/postulantes/perfil',
      content: <Text>Mi Perfil</Text>,
      before: <MenuItemIcon iconName="icon-light-person" />,
      id: 'miPerfil',
      key: 'mi-perfil',
      visibleIn: ['desktop'],
      linkoptions: linksUserMenu.options('gray'),
      metric: () => CrossSiteDataLayers.header('Mi Perfil'),
      portals: ['ZJAR'],
    },

    miPerfilHome: {
      href: '/',
      content: <Text>Inicio</Text>,
      before: <MenuItemIcon iconName="icon-light-home" />,
      id: 'miPerfil',
      key: 'mi-perfil',
      visibleIn: ['desktop'],
      linkoptions: linksUserMenu.options('gray'),
      metric: () => CrossSiteDataLayers.header('Mi Perfil'),
      portals: allPortalsBM,
    },

    miCv: {
      href: '/postulantes/curriculum',
      content: <Text>Mi {i18n.t('cv')}</Text>,
      before: <MenuItemIcon iconName="icon-light-file-text" />,
      key: 'mi-cv',
      visibleIn: ['mobile', 'desktop'],
      linkoptions: linksUserMenu.options('gray'),
      metric: () => CrossSiteDataLayers.header('Mi CV'),
      portals: allPortals,
    },

    mensajes: {
      href: '/postulantes/mensajes',
      content: <Text>Mensajes</Text>,
      before: <MenuItemIcon iconName="icon-light-email" />,
      id: 'mensajes',
      key: 'mensajes',
      visibleIn: ['mobile', 'desktop'],
      linkoptions: linksUserMenu.options('gray'),
      metric: () => CrossSiteDataLayers.header('Mensajes'),
      portals: allPortalsBM,
    },

    avisosGuardados: {
      href: '/postulantes/avisos-guardados',
      content: <Text>Mis favoritos</Text>,
      before: <MenuItemIcon iconName="icon-light-heart" />,
      key: 'mis-avisos-guardados',
      visibleIn: ['desktop'],
      linkoptions: linksUserMenu.options('gray'),
      metric: () => CrossSiteDataLayers.header('Avisos Guardados'),
      portals: allPortals,
    },

    ajustesAlertas: {
      href: '/postulantes/notificaciones-y-alertas',
      content: <Text>Ajustes de notificaciones y alertas</Text>,
      before: <MenuItemIcon iconName="icon-light-megaphone" />,
      id: 'ajutes-alertas',
      key: 'ajutes-alertas',
      visibleIn: ['mobile', 'desktop'],
      linkoptions: linksUserMenu.options('gray'),
      metric: () => CrossSiteDataLayers.header('Alertas'),
      portals: allPortals,
    },

    puestosEjecutivos: {
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      content: <LinkJovenesProfesionales />,
      key: 'puestos-ejecutivos',
      visibleIn: [],
      linkoptions: linksUserMenu.options('gray'),
      metric: () => CrossSiteDataLayers.header('Jovenes Profesionales'),
      portals: allPortals,
    },

    miCuenta: {
      href: '/postulantes/cuenta',
      content: <Text>Mi cuenta</Text>,
      before: <MenuItemIcon iconName="icon-light-settings-2" />,
      id: 'mi-cuenta',
      key: 'mi-cuenta',
      visibleIn: ['mobile', 'desktop'],
      linkoptions: linksUserMenu.options('gray'),
      metric: () => CrossSiteDataLayers.header('Mi cuenta'),
      portals: allPortals,
    },

    logout: {
      href: '/logout',
      content: <Text>Cerrar Sesión</Text>,
      before: <MenuItemIcon iconName="icon-light-log-out" />,
      key: 'logout',
      visibleIn: ['mobile', 'desktop'],
      linkoptions: linksUserMenu.options('gray'),
      metric: () => CrossSiteDataLayers.header('Logout'),
      portals: allPortals,
    },

    premium: {
      href: '#',
      content: <ButtonPremium />,
      key: 'premium',
      visibleIn: [],
      linkoptions: linksUserMenu.options('secondary'),
      metric: () => CrossSiteDataLayers.header('Premium'),
      portals: ['BMAR', 'BMMX', 'BMPE', 'BMCL'],
    },
  },

  navBarMenu: {
    notifications: visibleIn => ({
      content: <Notifications />,
      key: 'icon-notifications',
      visibleIn,
      linkoptions: linksUserMenu.options('darken'),
      metric: () => CrossSiteDataLayers.header('Notificaciones'),
      portals: allPortals,
    }),

    misPostulaciones: visibleIn => ({
      href: '/postulantes/postulaciones',
      content: 'Mis postulaciones',
      key: 'mis-postulaciones',
      visibleIn,
      linkoptions: linksUserMenu.options('darken'),
      metric: () => CrossSiteDataLayers.header('Mis Postulaciones'),
      portals: allPortals,
    }),
  },
}
