import { dataLink } from './data-link'
import { commonItems } from '../common-items'
import { IS_ZONA_JOBS } from '../../..'

export const loggedInMenuItems = ({ storeUrl }) => {
  const downloadAppSection = IS_ZONA_JOBS
    ? []
    : [commonItems.separador, commonItems.downloadApp(storeUrl), commonItems.separador]

  return [
    {
      key: 'user-menu',
      type: 'dropdown',
      visibleIn: ['mobile', 'desktop'],
      items: [
        dataLink.userMenu.miPerfil,
        dataLink.userMenu.miPerfilHome,
        dataLink.userMenu.miCv,
        dataLink.userMenu.avisosGuardados,
        dataLink.userMenu.mensajes,
        dataLink.userMenu.ajustesAlertas,
        dataLink.userMenu.puestosEjecutivos,
        dataLink.userMenu.premium,
        dataLink.userMenu.miCuenta,
        // MOBILE
        commonItems.separador,
        commonItems.explorar,
        commonItems.promedioSalarial,
        commonItems.listadoEmpresas,
        commonItems.mapaEmpleos,
        commonItems.separadorSitiosInteres,
        commonItems.sitiosDeInteres,
        commonItems.jovenesProfesionalesIcon,
        commonItems.puestosEjecutivos,
        commonItems.empleosInclusivos,
        commonItems.empleosPartTime,
        commonItems.practicasProfesionales,
        ...downloadAppSection,
        dataLink.userMenu.logout,
      ],
    },
    {
      key: 'navbar-menu',
      type: 'navbar',
      visibleIn: ['desktop'],
      items: [dataLink.navBarMenu.notifications(['desktop']), dataLink.navBarMenu.misPostulaciones(['desktop'])],
    },
  ]
}
