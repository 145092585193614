import {
  GET_APPLICANT,
  GET_APPLICANT_SUCCESS,
  GET_APPLICANT_ERROR,
  GET_APPLICANT_SUMMARY,
  GET_APPLICANT_SUMMARY_SUCCESS,
  GET_APPLICANT_SUMMARY_ERROR,
  GET_CURRICULUM,
  GET_CURRICULUM_SUCCESS,
  GET_CURRICULUM_ERROR,
  ApplicantActions,
  ADD_DATOS_PERSONALES,
  ADD_DATOS_PERSONALES_SUCCESS,
  ADD_DATOS_PERSONALES_ERROR,
  ADD_DATOS_PERSONALES_RESET,
  UPDATE_IMAGE_PROFILE,
  UPDATE_IMAGE_PROFILE_SUCCESS,
  UPDATE_IMAGE_PROFILE_ERROR,
  UPDATE_DATOS_CONTACTO,
  UPDATE_DATOS_CONTACTO_RESET,
  UPDATE_DATOS_CONTACTO_SUCCESS,
  UPDATE_DATOS_CONTACTO_ERROR,
  RESET_APPLICANT,
  UPDATE_IMAGE_PROFILE_RESET,
  UPDATE_DOCUMENTO,
  UPDATE_DOCUMENTO_SUCCESS,
  UPDATE_DOCUMENTO_ERROR,
  GET_VISUALIZACIONES_CV,
  GET_VISUALIZACIONES_CV_SUCCESS,
  GET_VISUALIZACIONES_CV_ERROR,
  GET_DATOS_PERSONALES,
  GET_DATOS_PERSONALES_SUCCESS,
  GET_DATOS_PERSONALES_ERROR,
} from '../types/applicant/applicant'
import { SHOW_SNACKBAR, HIDE_SNACKBAR } from '../types/applicant/curriculum/snackbar'
import {
  ADD_OR_UPDATE_ESTUDIO,
  ADD_OR_UPDATE_ESTUDIO_SUCCESS,
  ADD_OR_UPDATE_ESTUDIO_ERROR,
  ADD_OR_UPDATE_ESTUDIO_RESET,
  DELETE_ESTUDIO,
  DELETE_ESTUDIO_SUCCESS,
  DELETE_ESTUDIO_ERROR,
  DELETE_ESTUDIO_RESET,
  DELETE_REFERENCIA_ESTUDIO,
  DELETE_REFERENCIA_ESTUDIO_SUCCESS,
  DELETE_REFERENCIA_ESTUDIO_ERROR,
  DELETE_REFERENCIA_ESTUDIO_RESET,
  UPDATE_REFERENCIA_ESTUDIO,
  UPDATE_REFERENCIA_ESTUDIO_SUCCESS,
  UPDATE_REFERENCIA_ESTUDIO_ERROR,
  UPDATE_REFERENCIA_ESTUDIO_RESET,
  ADD_ESTUDIOS,
  ADD_ESTUDIOS_ERROR,
  ADD_ESTUDIOS_RESET,
  ADD_ESTUDIOS_SUCCESS,
  SIN_ESTUDIOS,
  SIN_ESTUDIOS_SUCCESS,
  SIN_ESTUDIOS_ERROR,
  SIN_ESTUDIOS_RESET,
  UPDATE_CERTIFICADO_ESTUDIO,
  UPDATE_CERTIFICADO_ESTUDIO_SUCCESS,
  UPDATE_CERTIFICADO_ESTUDIO_ERROR,
  UPDATE_CERTIFICADO_ESTUDIO_RESET,
  DELETE_CERTIFICADO_ESTUDIO_RESET,
  DELETE_CERTIFICADO_ESTUDIO_ERROR,
  DELETE_CERTIFICADO_ESTUDIO_SUCCESS,
  DELETE_CERTIFICADO_ESTUDIO,
} from '../types/applicant/curriculum/estudios'
import {
  UPDATE_IDIOMA,
  UPDATE_IDIOMA_SUCCESS,
  UPDATE_IDIOMA_ERROR,
  UPDATE_IDIOMA_RESET,
  DELETE_IDIOMA,
  DELETE_IDIOMA_SUCCESS,
  DELETE_IDIOMA_ERROR,
  DELETE_IDIOMA_RESET,
  ADD_IDIOMAS,
  ADD_IDIOMAS_ERROR,
  ADD_IDIOMAS_RESET,
  ADD_IDIOMAS_SUCCESS,
} from '../types/applicant/curriculum/idiomas'
import {
  DELETE_DISCAPACIDAD,
  DELETE_DISCAPACIDAD_RESET,
  DELETE_DISCAPACIDAD_SUCCESS,
  DELETE_DISCAPACIDAD_ERROR,
  ADD_DISCAPACIDAD,
  ADD_DISCAPACIDAD_RESET,
  ADD_DISCAPACIDAD_SUCCESS,
  ADD_DISCAPACIDAD_ERROR,
  GET_DISCAPACIDAD,
  GET_DISCAPACIDAD_RESET,
  GET_DISCAPACIDAD_SUCCESS,
  GET_DISCAPACIDAD_ERROR,
} from '../types/applicant/curriculum/discapacidad'
import {
  GET_CV_ADJUNTO,
  GET_CV_ADJUNTO_RESET,
  GET_CV_ADJUNTO_SUCCESS,
  GET_CV_ADJUNTO_ERROR,
  DELETE_CV_ADJUNTO,
  DELETE_CV_ADJUNTO_RESET,
  DELETE_CV_ADJUNTO_SUCCESS,
  DELETE_CV_ADJUNTO_ERROR,
  ADD_CV_ADJUNTO,
  ADD_CV_ADJUNTO_RESET,
  ADD_CV_ADJUNTO_SUCCESS,
  ADD_CV_ADJUNTO_ERROR,
} from '../types/applicant/curriculum/cv-adjunto'
import {
  GET_VIDEO_PRESENTACION,
  GET_VIDEO_PRESENTACION_RESET,
  GET_VIDEO_PRESENTACION_SUCCESS,
  GET_VIDEO_PRESENTACION_ERROR,
  ADD_VIDEO_PRESENTACION,
  ADD_VIDEO_PRESENTACION_RESET,
  ADD_VIDEO_PRESENTACION_SUCCESS,
  ADD_VIDEO_PRESENTACION_ERROR,
  DELETE_VIDEO_PRESENTACION,
  DELETE_VIDEO_PRESENTACION_RESET,
  DELETE_VIDEO_PRESENTACION_SUCCESS,
  DELETE_VIDEO_PRESENTACION_ERROR,
  GET_VIDEO_PRESENTACION_PREVIEW,
  GET_VIDEO_PRESENTACION_PREVIEW_ERROR,
  GET_VIDEO_PRESENTACION_PREVIEW_RESET,
  GET_VIDEO_PRESENTACION_PREVIEW_SUCCESS,
} from '../types/applicant/curriculum/video-presentacion'
import {
  GET_PREFERENCIA_SALARIAL,
  GET_PREFERENCIA_SALARIAL_SUCCESS,
  GET_PREFERENCIA_SALARIAL_ERROR,
  UPDATE_PREFERENCIA_SALARIAL,
  UPDATE_PREFERENCIA_SALARIAL_SUCCESS,
  UPDATE_PREFERENCIA_SALARIAL_ERROR,
  UPDATE_PREFERENCIA_SALARIAL_RESET,
  DELETE_PREFERENCIA_SALARIAL,
  DELETE_PREFERENCIA_SALARIAL_SUCCESS,
  DELETE_PREFERENCIA_SALARIAL_ERROR,
  DELETE_PREFERENCIA_SALARIAL_RESET,
} from '../types/applicant/curriculum/preferencia-salarial'
import {
  UPDATE_OBJETIVO_LABORAL,
  UPDATE_OBJETIVO_LABORAL_SUCCESS,
  UPDATE_OBJETIVO_LABORAL_ERROR,
  UPDATE_OBJETIVO_LABORAL_RESET,
  DELETE_OBJETIVO_LABORAL,
  DELETE_OBJETIVO_LABORAL_SUCCESS,
  DELETE_OBJETIVO_LABORAL_ERROR,
  DELETE_OBJETIVO_LABORAL_RESET,
} from '../types/applicant/curriculum/objetivo-laboral'
import {
  UPDATE_SKILLS,
  UPDATE_SKILLS_SUCCESS,
  UPDATE_SKILLS_ERROR,
  UPDATE_SKILLS_RESET,
  DELETE_SKILLS,
  DELETE_SKILLS_SUCCESS,
  DELETE_SKILLS_ERROR,
  DELETE_SKILLS_RESET,
  REPLACE_SKILLS_RESET,
  REPLACE_SKILLS_ERROR,
  REPLACE_SKILLS_SUCCESS,
  REPLACE_SKILLS,
} from '../types/applicant/curriculum/skills'
import {
  UPDATE_PDA,
  UPDATE_PDA_SUCCESS,
  UPDATE_PDA_ERROR,
  UPDATE_PDA_RESET,
  GET_LINK_REPORTE,
  GET_LINK_REPORTE_SUCCESS,
  GET_LINK_REPORTE_ERROR,
  GET_LINK_REPORTE_RESET,
} from '../types/applicant/curriculum/pda'
import {
  DELETE_EXPERIENCIA,
  DELETE_EXPERIENCIA_SUCCESS,
  DELETE_EXPERIENCIA_ERROR,
  DELETE_EXPERIENCIA_RESET,
  DELETE_REFERENCIA_EXPERIENCIA,
  DELETE_REFERENCIA_EXPERIENCIA_SUCCESS,
  DELETE_REFERENCIA_EXPERIENCIA_ERROR,
  DELETE_REFERENCIA_EXPERIENCIA_RESET,
  UPDATE_EXPERIENCIA,
  UPDATE_EXPERIENCIA_SUCCESS,
  UPDATE_EXPERIENCIA_ERROR,
  UPDATE_EXPERIENCIA_RESET,
  UPDATE_REFERENCIA_LABORAL,
  UPDATE_REFERENCIA_LABORAL_SUCCESS,
  UPDATE_REFERENCIA_LABORAL_ERROR,
  UPDATE_REFERENCIA_LABORAL_RESET,
  PRIMER_EMPLEO,
  PRIMER_EMPLEO_SUCCESS,
  PRIMER_EMPLEO_ERROR,
  PRIMER_EMPLEO_RESET,
  UPDATE_EXPERIENCIAS,
  UPDATE_EXPERIENCIAS_SUCCESS,
  UPDATE_EXPERIENCIAS_ERROR,
  UPDATE_EXPERIENCIAS_RESET,
} from '../types/applicant/curriculum/experiencia-laboral'
import { ApplicantState, initialState } from '../types/applicant/commons'
import { TIENE_CV, TIENE_CV_ERROR, TIENE_CV_RESET, TIENE_CV_SUCCESS } from '../types/applicant/curriculum/tiene-cv'
import {
  UPDATE_DATOS_PERSONALES_ONBORDING,
  UPDATE_DATOS_PERSONALES_ONBORDING_ERROR,
  UPDATE_DATOS_PERSONALES_ONBORDING_SUCCESS,
} from '../types/applicant/curriculum/datos-personales-onbording'
import { status } from '../types/status'
import {
  ACTUALIZAR_FECHA_CV,
  ACTUALIZAR_FECHA_CV_ERROR,
  ACTUALIZAR_FECHA_CV_RESET,
  ACTUALIZAR_FECHA_CV_SUCCESS,
} from '../types/applicant/curriculum/fecha-cv'
import {
  GET_CERTIFICADO_ADJUNTO,
  GET_CERTIFICADO_ADJUNTO_ERROR,
  GET_CERTIFICADO_ADJUNTO_RESET,
  GET_CERTIFICADO_ADJUNTO_SUCCESS,
} from '../types/applicant/curriculum/certificado-adjunto'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state: any = initialState, action: ApplicantActions): ApplicantState => {
  switch (action.type) {
    case GET_PREFERENCIA_SALARIAL:
      return {
        ...state,
        preferenciaSalarialStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case GET_PREFERENCIA_SALARIAL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        preferenciaSalarialStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case GET_PREFERENCIA_SALARIAL_ERROR:
      return {
        ...state,
        preferenciaSalarialStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_PREFERENCIA_SALARIAL:
      return {
        ...state,
        updatePreferenciaSalarialStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_PREFERENCIA_SALARIAL_SUCCESS:
      return {
        ...state,
        updatePreferenciaSalarialStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_PREFERENCIA_SALARIAL_ERROR:
      return {
        ...state,
        updatePreferenciaSalarialStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_PREFERENCIA_SALARIAL_RESET:
      return {
        ...state,
        updatePreferenciaSalarialStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case DELETE_PREFERENCIA_SALARIAL:
      return {
        ...state,
        deletePreferenciaSalarialStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case DELETE_PREFERENCIA_SALARIAL_SUCCESS:
      return {
        ...state,
        deletePreferenciaSalarialStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case DELETE_PREFERENCIA_SALARIAL_ERROR:
      return {
        ...state,
        deletePreferenciaSalarialStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_PREFERENCIA_SALARIAL_RESET:
      return {
        ...state,
        deletePreferenciaSalarialStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case GET_APPLICANT:
      return {
        ...state,
        getApplicantStatus: status.loading,
      }
    case GET_APPLICANT_SUCCESS:
      return {
        ...state,
        applicant: { ...state.applicant, ...action.payload },
        getApplicantStatus: status.success,
      }
    case GET_APPLICANT_ERROR:
      return {
        ...state,
        getApplicantStatus: status.error,
      }
    case GET_APPLICANT_SUMMARY:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_APPLICANT_SUMMARY_SUCCESS:
      return {
        ...state,
        applicant: { ...state.applicant, ...action.payload },
        loading: false,
        success: true,
        error: false,
      }
    case GET_APPLICANT_SUMMARY_ERROR:
      return {
        ...state,
        applicant: null,
        loading: false,
        error: true,
      }
    case GET_DATOS_PERSONALES:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_DATOS_PERSONALES_SUCCESS:
      return {
        ...state,
        datosPersonales: { ...state.applicant, ...action.payload },
        loading: false,
        success: true,
        error: false,
      }
    case GET_DATOS_PERSONALES_ERROR:
      return {
        ...state,
        applicant: null,
        loading: false,
        error: true,
      }
    case GET_CURRICULUM:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_CURRICULUM_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: false,
      }
    case GET_CURRICULUM_ERROR:
      return {
        ...state,
        pda: null,
        curriculum: null,
        datosPersonales: null,
        loading: false,
        error: true,
      }
    case ADD_DATOS_PERSONALES:
      return {
        ...state,
        updateDatosPersonalesStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case ADD_DATOS_PERSONALES_RESET:
      return {
        ...state,
        updateDatosPersonalesStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case ADD_DATOS_PERSONALES_SUCCESS:
      return {
        ...state,
        applicant: { ...state.applicant, nombre: action.payload.nombre, apellido: action.payload.apellido },
        updateDatosPersonalesStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case ADD_DATOS_PERSONALES_ERROR:
      return {
        ...state,
        updateDatosPersonalesStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_DATOS_PERSONALES_ONBORDING:
      return {
        ...state,
        updateDatosPersonalesOnbordingStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_DATOS_PERSONALES_ONBORDING_SUCCESS:
      return {
        ...state,
        updateDatosPersonalesOnbordingStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_DATOS_PERSONALES_ONBORDING_ERROR:
      return {
        ...state,
        updateDatosPersonalesOnbordingStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_DISCAPACIDAD:
      return {
        ...state,
        deleteDiscapacidadStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case DELETE_DISCAPACIDAD_RESET:
      return {
        ...state,
        deleteDiscapacidadStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case DELETE_DISCAPACIDAD_SUCCESS:
      return {
        ...state,
        deleteDiscapacidadStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case DELETE_DISCAPACIDAD_ERROR:
      return {
        ...state,
        deleteDiscapacidadStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case ADD_DISCAPACIDAD:
      return {
        ...state,
        addDiscapacidadStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case ADD_DISCAPACIDAD_RESET:
      return {
        ...state,
        addDiscapacidadStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case ADD_DISCAPACIDAD_SUCCESS:
      return {
        ...state,
        addDiscapacidadStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case ADD_DISCAPACIDAD_ERROR:
      return {
        ...state,
        addDiscapacidadStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case GET_DISCAPACIDAD:
      return {
        ...state,
        getDiscapacidadStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case GET_DISCAPACIDAD_RESET:
      return {
        ...state,
        getDiscapacidadStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case GET_DISCAPACIDAD_SUCCESS:
      return {
        ...state,
        discapacidadURLToDownload: action.payload,
        getDiscapacidadStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case GET_DISCAPACIDAD_ERROR:
      return {
        ...state,
        getDiscapacidadStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case ADD_CV_ADJUNTO:
      return {
        ...state,
        addCvAdjuntoStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case ADD_CV_ADJUNTO_RESET:
      return {
        ...state,
        addCvAdjuntoStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case ADD_CV_ADJUNTO_SUCCESS:
      return {
        ...state,
        addCvAdjuntoStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case ADD_CV_ADJUNTO_ERROR:
      return {
        ...state,
        addCvAdjuntoStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case GET_CV_ADJUNTO:
      return {
        ...state,
        getCvAdjuntoStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case GET_CV_ADJUNTO_RESET:
      return {
        ...state,
        getCvAdjuntoStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case GET_CV_ADJUNTO_SUCCESS:
      return {
        ...state,
        cvAdjuntoURL: action.payload,
        getCvAdjuntoStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case GET_CV_ADJUNTO_ERROR:
      return {
        ...state,
        getCvAdjuntoStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case GET_CERTIFICADO_ADJUNTO:
      return {
        ...state,
        getCertificadoAdjuntoStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case GET_CERTIFICADO_ADJUNTO_RESET:
      return {
        ...state,
        getCertificadoAdjuntoStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case GET_CERTIFICADO_ADJUNTO_SUCCESS:
      return {
        ...state,
        certificadoAdjuntoURL: action.payload,
        getCertificadoAdjuntoStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case GET_CERTIFICADO_ADJUNTO_ERROR:
      return {
        ...state,
        getCertificadoAdjuntoStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_CV_ADJUNTO:
      return {
        ...state,
        deleteCvAdjuntoStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case DELETE_CV_ADJUNTO_RESET:
      return {
        ...state,
        deleteCvAdjuntoStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case DELETE_CV_ADJUNTO_SUCCESS:
      return {
        ...state,
        deleteCvAdjuntoStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case DELETE_CV_ADJUNTO_ERROR:
      return {
        ...state,
        deleteCvAdjuntoStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case GET_VIDEO_PRESENTACION:
      return {
        ...state,
        getVideoPresentacionStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case GET_VIDEO_PRESENTACION_RESET:
      return {
        ...state,
        getVideoPresentacionStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case GET_VIDEO_PRESENTACION_SUCCESS:
      return {
        ...state,
        videoPresentacionURL: action.payload,
        getVideoPresentacionStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case GET_VIDEO_PRESENTACION_ERROR:
      return {
        ...state,
        getVideoPresentacionStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case GET_VIDEO_PRESENTACION_PREVIEW:
      return {
        ...state,
        getVideoPresentacionPreviewStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case GET_VIDEO_PRESENTACION_PREVIEW_RESET:
      return {
        ...state,
        getVideoPresentacionPreviewStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case GET_VIDEO_PRESENTACION_PREVIEW_SUCCESS:
      return {
        ...state,
        videoPresentacionPreviewURL: action.payload,
        getVideoPresentacionPreviewStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case GET_VIDEO_PRESENTACION_PREVIEW_ERROR:
      return {
        ...state,
        getVideoPresentacionPreviewStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_VIDEO_PRESENTACION:
      return {
        ...state,
        deleteVideoPresentacionStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }

    case DELETE_VIDEO_PRESENTACION_SUCCESS:
      return {
        ...state,
        deleteVideoPresentacionStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case DELETE_VIDEO_PRESENTACION_ERROR:
      return {
        ...state,
        deleteVideoPresentacionStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_VIDEO_PRESENTACION_RESET:
      return {
        ...state,
        deleteVideoPresentacionStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case ADD_VIDEO_PRESENTACION:
      return {
        ...state,
        addVideoPresentacionStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case ADD_VIDEO_PRESENTACION_RESET:
      return {
        ...state,
        videoPresentacionURL: null,
        videoPresentacionPreviewURL: null,
        addVideoPresentacionStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case ADD_VIDEO_PRESENTACION_SUCCESS:
      return {
        ...state,
        addVideoPresentacionStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case ADD_VIDEO_PRESENTACION_ERROR:
      return {
        ...state,
        addVideoPresentacionStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_IMAGE_PROFILE:
      return {
        ...state,
        updateImageProfileStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_IMAGE_PROFILE_SUCCESS:
      return {
        ...state,
        applicant: { ...state.applicant, fotoURL: action.payload },
        updateImageProfileStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_IMAGE_PROFILE_ERROR:
      return {
        ...state,
        updateImageProfileStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_IMAGE_PROFILE_RESET:
      return {
        ...state,
        updateImageProfileStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case UPDATE_DATOS_CONTACTO:
      return {
        ...state,
        updateDatosContactoStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_DATOS_CONTACTO_RESET:
      return {
        ...state,
        updateDatosContactoStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case UPDATE_DATOS_CONTACTO_SUCCESS:
      return {
        ...state,
        updateDatosContactoStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_DATOS_CONTACTO_ERROR:
      return {
        ...state,
        updateDatosContactoStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case SHOW_SNACKBAR:
      return {
        ...state,
        showSnackbar: {
          visible: true,
          ...action.payload,
        },
      }
    case HIDE_SNACKBAR:
      return {
        ...state,
        showSnackbar: {
          ...state.showSnackbar,
          visible: false,
        },
      }
    case ADD_OR_UPDATE_ESTUDIO:
      return {
        ...state,
        addOrUpdateEstudioStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case ADD_OR_UPDATE_ESTUDIO_SUCCESS:
      return {
        ...state,
        addOrUpdateEstudioStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case ADD_OR_UPDATE_ESTUDIO_ERROR:
      return {
        ...state,
        addOrUpdateEstudioStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case ADD_OR_UPDATE_ESTUDIO_RESET:
      return {
        ...state,
        addOrUpdateEstudioStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case SIN_ESTUDIOS:
      return {
        ...state,
        sinEstudiosStatus: status.loading,
      }
    case SIN_ESTUDIOS_SUCCESS:
      return {
        ...state,
        sinEstudiosStatus: status.success,
      }
    case SIN_ESTUDIOS_ERROR:
      return {
        ...state,
        sinEstudiosStatus: status.error,
      }
    case SIN_ESTUDIOS_RESET:
      return {
        ...state,
        sinEstudiosStatus: status.initial,
      }
    case ADD_ESTUDIOS:
      return {
        ...state,
        addEstudiosStatus: status.loading,
      }
    case ADD_ESTUDIOS_SUCCESS:
      return {
        ...state,
        addEstudiosStatus: status.success,
      }
    case ADD_ESTUDIOS_ERROR:
      return {
        ...state,
        addEstudiosStatus: status.error,
      }
    case ADD_ESTUDIOS_RESET:
      return {
        ...state,
        addEstudiosStatus: status.initial,
      }
    case DELETE_ESTUDIO:
      return {
        ...state,
        deleteEstudioStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case DELETE_ESTUDIO_SUCCESS:
      return {
        ...state,
        deleteEstudioStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case DELETE_ESTUDIO_ERROR:
      return {
        ...state,
        deleteEstudioStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_ESTUDIO_RESET:
      return {
        ...state,
        deleteEstudioStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case DELETE_REFERENCIA_ESTUDIO:
      return {
        ...state,
        deleteReferenciaEstudioStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case DELETE_REFERENCIA_ESTUDIO_SUCCESS:
      return {
        ...state,
        deleteReferenciaEstudioStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case DELETE_REFERENCIA_ESTUDIO_ERROR:
      return {
        ...state,
        deleteReferenciaEstudioStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_REFERENCIA_ESTUDIO_RESET:
      return {
        ...state,
        deleteReferenciaEstudioStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case DELETE_IDIOMA:
      return {
        ...state,
        deleteIdiomaStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case DELETE_IDIOMA_SUCCESS:
      return {
        ...state,
        deleteIdiomaStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case DELETE_IDIOMA_ERROR:
      return {
        ...state,
        deleteIdiomaStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_IDIOMA_RESET:
      return {
        ...state,
        deleteIdiomaStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case UPDATE_REFERENCIA_ESTUDIO:
      return {
        ...state,
        updateReferenciaEstudioStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_REFERENCIA_ESTUDIO_SUCCESS:
      return {
        ...state,
        updateReferenciaEstudioStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_REFERENCIA_ESTUDIO_ERROR:
      return {
        ...state,
        updateReferenciaEstudioStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_REFERENCIA_ESTUDIO_RESET:
      return {
        ...state,
        updateReferenciaEstudioStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }

    case UPDATE_CERTIFICADO_ESTUDIO:
      return {
        ...state,
        updateCertificadoEstudioStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_CERTIFICADO_ESTUDIO_SUCCESS:
      return {
        ...state,
        updateCertificadoEstudioStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_CERTIFICADO_ESTUDIO_ERROR:
      return {
        ...state,
        updateCertificadoEstudioStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_CERTIFICADO_ESTUDIO_RESET:
      return {
        ...state,
        updateCertificadoEstudioStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case UPDATE_CERTIFICADO_ESTUDIO:
      return {
        ...state,
        updateCertificadoEstudioStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_CERTIFICADO_ESTUDIO_SUCCESS:
      return {
        ...state,
        updateCertificadoEstudioStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_CERTIFICADO_ESTUDIO_ERROR:
      return {
        ...state,
        updateCertificadoEstudioStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_CERTIFICADO_ESTUDIO_RESET:
      return {
        ...state,
        updateCertificadoEstudioStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case DELETE_CERTIFICADO_ESTUDIO:
      return {
        ...state,
        deleteCertificadoEstudioStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case DELETE_CERTIFICADO_ESTUDIO_SUCCESS:
      return {
        ...state,
        deleteCertificadoEstudioStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case DELETE_CERTIFICADO_ESTUDIO_ERROR:
      return {
        ...state,
        deleteCertificadoEstudioStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_CERTIFICADO_ESTUDIO_RESET:
      return {
        ...state,
        deleteCertificadoEstudioStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case UPDATE_REFERENCIA_LABORAL:
      return {
        ...state,
        updateReferenciaLaboralStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_REFERENCIA_LABORAL_SUCCESS:
      return {
        ...state,
        updateReferenciaLaboralStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_REFERENCIA_LABORAL_ERROR:
      return {
        ...state,
        updateReferenciaLaboralStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_REFERENCIA_LABORAL_RESET:
      return {
        ...state,
        updateReferenciaLaboralStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case UPDATE_IDIOMA:
      return {
        ...state,
        updateIdiomaStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_IDIOMA_SUCCESS:
      return {
        ...state,
        updateIdiomaStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_IDIOMA_ERROR:
      return {
        ...state,
        updateIdiomaStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_IDIOMA_RESET:
      return {
        ...state,
        updateIdiomaStatus: {
          success: false,
        },
      }
    case ADD_IDIOMAS:
      return {
        ...state,
        addIdiomasStatus: status.loading,
      }
    case ADD_IDIOMAS_SUCCESS:
      return {
        ...state,
        addIdiomasStatus: status.success,
      }
    case ADD_IDIOMAS_ERROR:
      return {
        ...state,
        addIdiomasStatus: status.error,
      }
    case ADD_IDIOMAS_RESET:
      return {
        ...state,
        addIdiomasStatus: status.initial,
      }
    case UPDATE_OBJETIVO_LABORAL:
      return {
        ...state,
        updateObjetivoLaboralStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_OBJETIVO_LABORAL_SUCCESS:
      return {
        ...state,
        updateObjetivoLaboralStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_OBJETIVO_LABORAL_ERROR:
      return {
        ...state,
        updateObjetivoLaboralStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_OBJETIVO_LABORAL_RESET:
      return {
        ...state,
        updateObjetivoLaboralStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case DELETE_OBJETIVO_LABORAL:
      return {
        ...state,
        deleteObjetivoLaboralStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case DELETE_OBJETIVO_LABORAL_SUCCESS:
      return {
        ...state,
        deleteObjetivoLaboralStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case DELETE_OBJETIVO_LABORAL_ERROR:
      return {
        ...state,
        deleteObjetivoLaboralStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_OBJETIVO_LABORAL_RESET:
      return {
        ...state,
        deleteObjetivoLaboralStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case UPDATE_SKILLS:
      return {
        ...state,
        updateSkillsStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_SKILLS_SUCCESS:
      return {
        ...state,
        updateSkillsStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_SKILLS_ERROR:
      return {
        ...state,
        updateSkillsStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_SKILLS_RESET:
      return {
        ...state,
        updateSkillsStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case REPLACE_SKILLS:
      return {
        ...state,
        replaceSkillsStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case REPLACE_SKILLS_SUCCESS:
      return {
        ...state,
        replaceSkillsStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case REPLACE_SKILLS_ERROR:
      return {
        ...state,
        replaceSkillsStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case REPLACE_SKILLS_RESET:
      return {
        ...state,
        replaceSkillsStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }

    case DELETE_SKILLS:
      return {
        ...state,
        deleteSkillsStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case DELETE_SKILLS_SUCCESS:
      return {
        ...state,
        deleteSkillsStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case DELETE_SKILLS_ERROR:
      return {
        ...state,
        deleteSkillsStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_SKILLS_RESET:
      return {
        ...state,
        deleteSkillsStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case DELETE_EXPERIENCIA:
      return {
        ...state,
        deleteExperienciaStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case DELETE_EXPERIENCIA_SUCCESS:
      return {
        ...state,
        deleteExperienciaStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case DELETE_EXPERIENCIA_ERROR:
      return {
        ...state,
        deleteExperienciaStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_EXPERIENCIA_RESET:
      return {
        ...state,
        deleteExperienciaStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case DELETE_REFERENCIA_EXPERIENCIA:
      return {
        ...state,
        deleteReferenciaExperienciaStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case DELETE_REFERENCIA_EXPERIENCIA_SUCCESS:
      return {
        ...state,
        deleteReferenciaExperienciaStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case DELETE_REFERENCIA_EXPERIENCIA_ERROR:
      return {
        ...state,
        deleteReferenciaExperienciaStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case DELETE_REFERENCIA_EXPERIENCIA_RESET:
      return {
        ...state,
        deleteReferenciaExperienciaStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case UPDATE_PDA:
      return {
        ...state,
        updatePdaStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_PDA_SUCCESS:
      return {
        ...state,
        updatePdaStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_PDA_ERROR:
      return {
        ...state,
        updatePdaStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_PDA_RESET:
      return {
        ...state,
        updatePdaStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case GET_LINK_REPORTE:
      return {
        ...state,
        refNamePda: action.payload,
        getLinkReporteStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case GET_LINK_REPORTE_SUCCESS:
      return {
        ...state,
        linkReportePda: action.payload,
        getLinkReporteStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case GET_LINK_REPORTE_ERROR:
      return {
        ...state,
        getLinkReporteStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case GET_LINK_REPORTE_RESET:
      return {
        ...state,
        refNamePda: null,
        linkReportePda: null,
        getLinkReporteStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case UPDATE_EXPERIENCIA:
      return {
        ...state,
        updateExperienciaStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_EXPERIENCIA_SUCCESS:
      return {
        ...state,
        updateExperienciaStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_EXPERIENCIA_ERROR:
      return {
        ...state,
        updateExperienciaStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_EXPERIENCIA_RESET:
      return {
        ...state,
        updateExperienciaStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case UPDATE_EXPERIENCIAS:
      return {
        ...state,
        updateExperienciasStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_EXPERIENCIAS_SUCCESS:
      return {
        ...state,
        updateExperienciasStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_EXPERIENCIAS_ERROR:
      return {
        ...state,
        updateExperienciasStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case UPDATE_EXPERIENCIAS_RESET:
      return {
        ...state,
        updateExperienciasStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case RESET_APPLICANT:
      return {
        ...state,
        applicant: null,
      }
    case PRIMER_EMPLEO:
      return {
        ...state,
        curriculum: {
          ...state.curriculum,
        },
        primerEmpleoStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case PRIMER_EMPLEO_SUCCESS:
      return {
        ...state,
        primerEmpleoStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case PRIMER_EMPLEO_ERROR:
      return {
        ...state,
        curriculum: {
          ...state.curriculum,
          buscaPrimerEmpleo: false,
        },
        primerEmpleoStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case PRIMER_EMPLEO_RESET:
      return {
        ...state,
        primerEmpleoStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case TIENE_CV:
      return {
        ...state,
        tieneCVStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case TIENE_CV_SUCCESS:
      return {
        ...state,
        tieneCV: action.payload,
        tieneCVStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case TIENE_CV_ERROR:
      return {
        ...state,
        tieneCVStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case TIENE_CV_RESET:
      return {
        ...state,
        tieneCV: null,
        tieneCVStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case UPDATE_DOCUMENTO:
      return {
        ...state,
        updateDocumentoStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case UPDATE_DOCUMENTO_SUCCESS:
      return {
        ...state,
        updateDocumentoStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case UPDATE_DOCUMENTO_ERROR:
      return {
        ...state,
        updateDocumentoStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case ACTUALIZAR_FECHA_CV:
      return {
        ...state,
        actualizarFechaCVStatus: status.loading,
      }
    case ACTUALIZAR_FECHA_CV_SUCCESS:
      return {
        ...state,
        actualizarFechaCVStatus: status.success,
      }
    case ACTUALIZAR_FECHA_CV_ERROR:
      return {
        ...state,
        actualizarFechaCVStatus: status.error,
      }
    case ACTUALIZAR_FECHA_CV_RESET:
      return {
        ...state,
        actualizarFechaCVStatus: status.initial,
      }
    case GET_VISUALIZACIONES_CV:
      return {
        ...state,
        visualizacionesCVStatus: status.loading,
      }
    case GET_VISUALIZACIONES_CV_SUCCESS:
      return {
        ...state,
        visualizacionesCVStatus: status.success,
        visualizacionesCV: action.payload,
      }
    case GET_VISUALIZACIONES_CV_ERROR:
      return {
        ...state,
        visualizacionesCVStatus: status.error,
      }
    default:
      return state
  }
}

export default reducers
