/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import { dateUtils } from '@navent-jobs/config'
import { makeFichaAvisoURL } from '@navent-jobs/interlinking'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// components
import CardPlaceholder from './CardPlaceholder'
import DataCol from './components/DataCol'
import LastDataCol from './components/LastDataCol'
import HeaderCol from './components/HeaderCol'
import ApplicationStatus from './components/ApplicationStatus'

// mixins
import { CardComponentWrapper, CardContentWrapper, Highlight, CardContainer } from './mixins'

// constants
import { IS_ZONA_JOBS, SITE_ID } from '../../constants'
import features from '../../constants/features-per-country'
import { planConfig } from './constants/planConfig'
import { DataLayers } from '../../constants/gtm-events-avisos-guardados'
import { CardDataLayers } from '../../constants/gtm-events-cards'

// utils
import { daysOfPublication } from '../../utils/calculate-days-publication'

// store
import { setSaveJobPosting, deleteSaveJobPosting } from '../../redux/actions/save-job-posting'

const saveJobPostingEnabled = features[SITE_ID || 'BMAR']?.saveJobPosting?.enabled
const gptwEnabled = features[SITE_ID || 'BMAR']?.gptw?.enabled

const getRating = (gptwUrl, promedioEmpresa) => {
  return !gptwEnabled || !gptwUrl ? promedioEmpresa : null
}

const CardComponent = props => {
  const dispatch = useDispatch()
  const { isDesktop } = useWindowSize()
  const {
    aviso,
    link,
    disableLink,
    onClick,
    handleMetrics,
    activeAvisoId,
    className,
    isUserLoggedIn,
    ubicacion,
    index,
    mobileFormat = false,
    clampDescription,
    sameHeight,
    disableSaveJobPosting,
    hideCompanyRow = false,
    notInterestedJobPostingEnabled = false,
    handleNotInterested,
  } = props
  const {
    id,
    logoURL,
    empresa,
    localizacion,
    titulo,
    detalle,
    tipoAviso,
    fechaPublicacion,
    confidencial,
    aptoDiscapacitado,
    estadoPostulacion,
    visitadoPorPostulante,
    modalidadTrabajo,
    guardado,
    match,
    tipoTrabajo,
    promedioEmpresa,
    gptwUrl,
    portal,
    cantidadVacantes,
  } = aviso
  const rating = getRating(gptwUrl, promedioEmpresa)
  const tipoAvisoCross = IS_ZONA_JOBS && portal?.toLowerCase() === 'bumeran' ? 'bumeran' : tipoAviso
  const [savePosting, setSavePosting] = useState<boolean>(guardado)
  const { isMobile } = useWindowSize()
  const avisoNuevo = dateUtils.getTiempoPublicado(fechaPublicacion) < 1
  const [isReading, setReading] = useState(isUserLoggedIn && activeAvisoId === id && id)
  const [isRead, setRead] = useState(isUserLoggedIn && visitadoPorPostulante && id)

  const refTo = disableLink
    ? undefined
    : link || makeFichaAvisoURL({ id, titulo, empresa: { denominacion: empresa, confidencial } })

  const avisoCrossPais = aviso && aviso.idPais

  const estadoPostulacionRealizada = estadoPostulacion === 'REALIZADA'
  const estadoPostulacionEspera = estadoPostulacion === 'ESPERA'

  const avisoKey = `${index}-${id}-${ubicacion?.replace(/[^\w]/gi, '') || ''}`

  const savePostingDispatcher = () => {
    if (!savePosting) {
      DataLayers.guardarAvisoListado(window?.location?.pathname)
      dispatch(setSaveJobPosting({ id, savePosting }))
      return
    }
    dispatch(deleteSaveJobPosting({ id, savePosting }))
  }

  const handleSavePosting = () => {
    setSavePosting(!savePosting)
    savePostingDispatcher()
  }

  useEffect(() => {
    if (isUserLoggedIn && activeAvisoId === id && isReading !== id) {
      setReading(id)
    }

    if (isUserLoggedIn && activeAvisoId !== id && isReading === id) {
      setReading(false)
      setRead(id)
    }
  }, [activeAvisoId, id, isReading, isUserLoggedIn])

  return (
    <CardComponentWrapper
      className={className}
      tipoAviso={tipoAvisoCross}
      read={isRead}
      applyed={estadoPostulacionRealizada || estadoPostulacionEspera}
      mobileFormat={mobileFormat}
    >
      <CardContentWrapper
        onClick={event => {
          if (handleMetrics) {
            handleMetrics()
          } else {
            CardDataLayers.avisoSelected(aviso, ubicacion, index)
          }

          if (onClick) {
            onClick({ event, id: id || null })
          }
        }}
        href={tipoAvisoCross !== 'bumeran' ? refTo : `https://www.bumeran.com.ar${refTo}`}
        target={isMobile ? '_self' : '_blank'}
        sameHeight={sameHeight}
      >
        <CardContainer tipoAviso={tipoAvisoCross} mobileFormat={mobileFormat} sameHeight={sameHeight}>
          {planConfig[tipoAvisoCross].showHighlight && (
            <Highlight portal={IS_ZONA_JOBS ? 'zonajobs' : 'bumeran'} tipoAviso={tipoAvisoCross} />
          )}

          <HeaderCol
            tipoAviso={tipoAvisoCross}
            confidencial={confidencial}
            logoURL={logoURL}
            estadoPostulacion={estadoPostulacion}
            estadoPostulacionEspera={estadoPostulacionEspera}
            isDesktop={isDesktop}
            empresa={empresa}
            cantidadDeDias={daysOfPublication({ fechaPublicacion })}
            avisoNuevo={avisoNuevo}
            titulo={titulo}
            detalle={detalle}
            promedioEmpresa={rating}
            isRead={isRead}
            mobileFormat={mobileFormat}
            clamp={clampDescription}
            cantidadVacantes={cantidadVacantes}
            hideCompanyRow={hideCompanyRow}
          />

          <DataCol
            avisoCrossPais={avisoCrossPais}
            localizacion={localizacion}
            modalidadTrabajo={modalidadTrabajo}
            tipoAviso={tipoAvisoCross}
            tipoTrabajo={tipoTrabajo}
            avisoMatch={match}
            mobileFormat={mobileFormat}
          />

          <LastDataCol
            aptoDiscapacitado={aptoDiscapacitado}
            tipoAviso={tipoAvisoCross}
            mobileFormat={mobileFormat}
            gptwUrl={gptwUrl}
            isDesktop={isDesktop}
          />
        </CardContainer>
      </CardContentWrapper>

      <ApplicationStatus
        index={avisoKey}
        tipoAviso={tipoAvisoCross}
        read={isRead}
        estadoPostulacionRealizada={estadoPostulacionRealizada}
        estadoPostulacionEspera={estadoPostulacionEspera}
        saveJobPostingEnabled={saveJobPostingEnabled && !disableSaveJobPosting}
        handleSavePosting={handleSavePosting}
        savePosting={savePosting}
        isDesktop={isDesktop}
        mobileFormat={mobileFormat}
        notInterestedJobPostingEnabled={notInterestedJobPostingEnabled}
        id={id}
        handleNotInterested={handleNotInterested}
      />
    </CardComponentWrapper>
  )
}

interface CardProps {
  id?: string
  aviso: {
    id: number
    logoURL: string
    empresa: string
    localizacion: string
    titulo: string
    detalle: string
    tipoTrabajo: string
    fechaPublicacion: string
    guardado: boolean
    confidencial: boolean
    tipoAviso: string
    aptoDiscapacitado: boolean
    planPublicacion: {
      id: number
      nombre: string
    }
    portal: string
  }
  titulo?: string
  nombreEmpresa?: string
  ubicacion?: string
  index?: number
  diasPublicacion?: string
  logo?: string
  logoEmpty?: string
  link?: string
  skin?: string
  disableLink?: boolean
  onClick?: Function
  handleMetrics?: Function
  hasError?: boolean
  isLoading?: boolean
  activeAvisoId?: string
  dataSaveJobPosting?: boolean
  disableSaveJobPosting?: boolean
  hideCompanyRow?: boolean
  notInterestedJobPostingEnabled?: boolean
  handleNotInterested?: Function
}

const Card = (props: CardProps) => {
  const renderOnLoad = () => {
    return <CardPlaceholder />
  }

  const renderOnError = () => {
    return (
      <div className="main">
        <h1>Ocurrió un error.</h1>
      </div>
    )
  }

  const renderOnSuccess = () => {
    return <CardComponent {...props} />
  }

  const { hasError, isLoading } = props

  if (hasError) {
    return renderOnError()
  }

  if (isLoading) {
    return renderOnLoad()
  }

  return renderOnSuccess()
}

/**
 * De esta manera, pedimos al store los datos del postulante y locations.
 */
const state = ({ applicantStore }) => {
  return {
    isUserLoggedIn: applicantStore && applicantStore.applicant,
  }
}

const ComponentWithStore = connect(state)(Card)
export default ComponentWithStore
