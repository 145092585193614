import { dateUtils } from '@navent-jobs/config'
import { SITE_ID } from '../constants'

interface DaysOfPublicationProps {
  fechaPublicacion: string
  section?: string
}

export const daysOfPublication = ({ fechaPublicacion, section }: DaysOfPublicationProps) => {
  // retorna día en formato fecha (el 13 de abril / hoy)
  const cantidadDeDias = dateUtils.getNumberOfDays(fechaPublicacion)
  // retorna día en number (1,2,3)
  const cantidadDeDiasAlterno = dateUtils.getTiempoPublicado(fechaPublicacion)
  const hasMyApplications = section === 'mis-postulaciones'

  if (SITE_ID !== 'ZJAR') {
    if (cantidadDeDiasAlterno === 0) {
      return `${hasMyApplications ? 'Hoy' : 'Publicado hoy'}`
    }

    if (cantidadDeDiasAlterno === 1) {
      return `${hasMyApplications ? 'Ayer' : 'Publicado ayer'}`
    }

    if (cantidadDeDiasAlterno >= 15 && cantidadDeDiasAlterno <= 29) {
      return `${hasMyApplications ? 'Más de 15 días' : 'Publicado hace más de 15 días'}`
    }

    if (cantidadDeDiasAlterno >= 30) {
      return `${hasMyApplications ? 'Más de 30 días' : 'Publicado hace más de 30 días'}`
    }
  }

  const textDayOfPublication = hasMyApplications
    ? `Hace ${cantidadDeDiasAlterno} días`
    : `Publicado hace ${cantidadDeDiasAlterno} días`

  return SITE_ID === 'ZJAR' ? `Publicado ${cantidadDeDias}` : textDayOfPublication
}
