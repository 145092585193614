import { TagManager } from '@navent-jobs/config'

export const CardDataLayers = {
  avisoSelected: (aviso, ubicacion, index) => {
    const localizacion = aviso.localizacion.split(', ')
    return TagManager.Push({
      event: 'clickAviso',
      fechaPublicado: aviso.fechaPublicacion,
      idAviso: aviso.id,
      tipoAnuncio: aviso.planPublicacion.nombre,
      empresa: aviso.empresa.denominacion || 'Confidencial',
      // subarea: aviso.subArea,
      subareaId: aviso.idSubarea,
      // area: aviso.area,
      areaId: aviso.idArea,
      provincia: localizacion[1] || 'Buenos Aires',
      ciudad: localizacion[0],
      posicionAviso: index,
      ubicacion,
      // estado: 'activo',
      aptoDiscapacitado: aviso.aptoDiscapacitado,
      tipoPuesto: aviso.tipoTrabajo,
    })
  },
  avisoFakeTracking: (action, titulo) => {
    return TagManager.Push({
      event: `${action === 'view' ? 'view_promotion' : 'select_promotion'}`,
      creative_name: `Aviso falso - ${titulo}`,
    })
  },
}
