import styled from 'styled-components'
import Image from '@navent-jobs/ui-kit/components/Image'

export const Text = styled.p`
  margin: 0;
  line-height: 20px;
  margin-left: 12px;
  font-size: 16px;
  font-weight: 600;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    line-height: 44px;
  }
`

export const Container = styled.div``

export const ImageCustom = styled(Image)`
  width: 20px;
`
