import { GeolocationType } from '../../../services/geolocation-service'
import { status, StatusType } from '../status'

export interface ProfileSummary {
  id: number
  nombre: string
  apellido: string
  email: string
  fotoURL: string
  mailValidado: boolean
}

export interface ProfileExtraData {
  curriculum?: {
    descripcion: string
    salarioPreferencia: number
    tienePerfilTecnologia: boolean
    referencias
    estudios
    id: number
    conocimientosAdicionales
    idiomas
  }
  datosPersonales?: {
    id: number
    nombre: string
    apellido: string
    email: string
    fotoURL: string

    // TODO check next types
    cvAdjuntoURL: string
    certificadoAdjuntoURL: string
    disabilityCertificateURL
    tipoDocumento
    numeroDocumento: number
    fechaNacimiento: string
    genero
    codigoPostal: number
    direccion: string
    estadoCivil: string
    discapacidad: string
    tieneMovilidadPropia: boolean
    tieneLicenciaConducir: boolean
    tienePerfilPDA: boolean
    videoPresentacionURL
    ultimaConfirmacionDatos
    resumenPDA: string
    esPremium: boolean
  }
}

interface IdName {
  id: number
  nombre: string
}

interface Pais {
  id: number
  nombre: string
  isoCode: string
}

interface Direccion {
  pais: Pais
  provincia: IdName | null
  localidad: IdName | null
  direccion: string | null
}

interface EstadoCivil {
  id: number
  nombre: string
}

export interface Telefono {
  numero: string | null
  prefijo: string | null
}

interface TipoDocumento {
  id: number
  nombre: string
}

interface TipoLicencia {
  id: number
  nombre: string
}

interface Attachments {
  CV?: {
    original: string
  }
  DISABILITY_CERTIFICATE?: {
    original: string
  }
  VIDEO_CV?: {
    preview?: string
    original: string
  }
}

export interface DatosPersonales {
  id: number
  nombre: string
  apellido: string
  email: string
  codigoPostal: string | null
  domicilio: Direccion
  discapacidad: string | null
  estadoCivil: EstadoCivil | null
  fechaNacimiento: string | null
  fotoURL: string | null
  genero: string | null
  numeroDocumento: string | null
  paisNacimiento: Pais
  paisResidencia: Pais
  paisRegistro: Pais
  telefonoCelular: Telefono
  telefonoFijo: Telefono
  tipoDocumento: TipoDocumento | null
  cvAdjuntoURL: string | null
  certificadoAdjuntoURL: string | null
  videoPresentacionURL: string | null
  direccion: string | null
  tieneMovilidadPropia: boolean | null
  tieneLicenciaConducir: boolean | null
  disabilityCertificateURL: string | null
  tienePerfilPDA: boolean
  adjuntos
  attachments: Attachments
  ultimaConfirmacionDatos
  resumenPDA
  licenciasConducir?: TipoLicencia[] | []
}

export interface CertificadoEstudio {
  id: number | null
  estudioId?: number
  url: string | null
  file: File | null
}

export interface ReferenciaEstudio {
  id: number | null
  estudioId: number
  nombre: string
  apellido: string
  email: string
  telefonoPrefijo: string
  telefonoNumero: string
  relacion: string
}

export interface ReferenciaLaboral {
  puesto
  id: number | null
  experienciaLaboralId: number
  nombre: string
  apellido: string
  email: string
  telefonoPrefijo: string
  telefonoNumero: string
  relacion: string
}

export type Profile = ProfileSummary & ProfileExtraData

export interface UpdateDatosPersonales {
  apellido: string
  nombre: string
  paisNacimientoId: number | null
  documento: string | null
  estadoCivilId: number | null
  fechaNacimiento: string | null
  genero: string | null
  tieneLicenciaConducir: boolean
  tieneMovilidadPropia: boolean
  tipoDocumentoId: number | null
  licenciasConducir: number[] | null
}

export interface UpdateDocumentoPayload {
  documento: string | null
  tipoDocumentoId: number | null
  productSku?: string
}

export interface UpdateDatosRegistro {
  apellido: string
  nombre: string
  documento: string
  tipoDocumentoId: number
  celularPrefijo: string
  celularNumero: string
}

export interface UpdateDatosContactoType {
  paisResidenciaId: number
  email: string
  celularPrefijo: string
  celularNumero: string
  telefonoFijoPrefijo: string | null
  telefonoFijoNumero: string | null
  geolocation: GeolocationType
}

export interface AddOrUpdateEstudioType {
  estudio: {
    areaEstudioId: number
    estadoEstudioId: number
    fechaFin: string | null
    fechaInicio: string
    institucionEducativa: {
      institucionId: number | null
      nombre: string
    }
    paisId: number
    tipoEstudioId: number
    titulo: {
      nombre: string
    }
  }
  estudioId: string | null
  origen?: string
}

export interface SinEstudioEstudioType {
  origen?: string
}

export interface UpdateIdiomaType {
  operationType: 'alta' | 'modificacion'
  idIdioma: number
  nivelOral: number
  nivelEscrito: number
  origen?: string
}

export interface UpdateSkillsType {
  normalizados: {
    conocimientoId?: number
    calificadorId: number
    conocimientoEspecificoId: number
    grupoId: number
    nivelCalificadorId: number
    tipoId: number
  }[]
  desnormalizados: {
    titulo: string
    descripcion: string
  }[]
  origen?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ReplaceSkillsType extends UpdateSkillsType {}

export interface AddIdiomasType {
  idiomaId: number
  nivelEscritoId: number
  nivelOralId: number
}

export interface DeleteSkillType {
  type: 'NORMALIZADO' | 'DESNORMALIZADO'
  id: number
}

export interface UpdateExperienciaType {
  id?: number
  cantidadPersonasACargo: number | null
  detalle: string | null
  empresa: {
    empresaId: null
    nombre: string
  }
  fechaFin: string | null
  fechaInicio: string
  industriaId: number
  manejaPresupuesto: boolean
  nivelPuestoId: number
  paisId: number
  puesto: {
    puestoId: null
    nombre: string
  }
  areaId: number
  subAreaId: number
  origen?: string
}

export interface ReplaceExperienciasType {
  experiencias: UpdateExperienciaType[]
  origen?: string
}

export interface PrimerEmpleoType {
  origen?: string
}

export interface OperationStatus {
  success: boolean
  loading: boolean
  error: boolean
}

export const defaultOperationStatus: OperationStatus = {
  success: false,
  loading: false,
  error: false,
}

export enum AdjuntoType {
  CV,
  DISABILITY_CERTIFICATE,
  VIDEO_CV,
}

export interface ArchivoAdjuntoDiscapacidad {
  description: string
  file
}

export interface GetArchivoAdjuntoDiscapacidad {
  fileName: string
  countryCode: string
}

export interface GetArchivoCertificadoAdjunto {
  fileName: string
  countryCode: string
}
export interface GetArchivoCvAdjunto {
  fileName: string
  countryCode: string
}

export interface GetArchivoVideo {
  fileName: string
  countryCode: string
}

export interface ShowSnackbarType {
  visible: boolean
  text: string
  icon: string | null
  error: boolean
}

export const defaultShowSnackbar: ShowSnackbarType = {
  visible: false,
  text: '',
  icon: '',
  error: false,
}

export interface AddVideoCVType {
  file: File
  update: boolean
}

export interface VisualizacionesCV {
  visualizaciones: number
  empresas: number
}

export interface SalarioType {
  salario: number
  origen?: string
}

export interface ObjetivoLaboralType {
  objetivo: string
  origen?: string
}

export interface ApplicantState {
  applicant: Profile | null
  datosPersonales: DatosPersonales | null
  pda
  curriculum
  tieneCV: boolean | null
  primerEmpleoStatus: OperationStatus
  pathDescargaCv: string | null
  refNamePda: string | null
  linkReportePda: string | null
  success: boolean
  loading: boolean
  error: boolean
  showSnackbar: ShowSnackbarType
  updateDatosPersonalesStatus: OperationStatus
  updateDatosPersonalesOnbordingStatus: OperationStatus
  updateImageProfileStatus: OperationStatus
  updateDatosContactoStatus: OperationStatus
  sinEstudiosStatus: OperationStatus
  addOrUpdateEstudioStatus: OperationStatus
  addEstudiosStatus: OperationStatus
  deleteEstudioStatus: OperationStatus
  deleteReferenciaEstudioStatus: OperationStatus
  deleteIdiomaStatus: OperationStatus
  updateReferenciaEstudioStatus: OperationStatus
  updateCertificadoEstudioStatus: OperationStatus
  deleteCertificadoEstudioStatus: OperationStatus
  updateReferenciaLaboralStatus: OperationStatus
  updateIdiomaStatus: OperationStatus
  addIdiomasStatus: OperationStatus
  deleteDiscapacidadStatus: OperationStatus
  addDiscapacidadStatus: OperationStatus
  getDiscapacidadStatus: OperationStatus
  discapacidadURLToDownload: string | null
  addVideoPresentacionStatus: OperationStatus
  addCvAdjuntoStatus: OperationStatus
  getCvAdjuntoStatus: OperationStatus
  getCertificadoAdjuntoStatus: OperationStatus
  getVideoPresentacionStatus: OperationStatus
  getVideoPresentacionPreviewStatus: OperationStatus
  videoPresentacionURL: string | null
  videoPresentacionPreviewURL: string | null
  deleteVideoPresentacionStatus: OperationStatus
  deleteCvAdjuntoStatus: OperationStatus
  cvAdjuntoURL: string | null
  certificadoAdjuntoURL: string | null
  preferenciaSalarial: number | null
  preferenciaSalarialStatus: OperationStatus
  updatePreferenciaSalarialStatus: OperationStatus
  deletePreferenciaSalarialStatus: OperationStatus
  updateObjetivoLaboralStatus: OperationStatus
  deleteObjetivoLaboralStatus: OperationStatus
  updateSkillsStatus: OperationStatus
  replaceSkillsStatus: OperationStatus
  deleteSkillsStatus: OperationStatus
  deleteExperienciaStatus: OperationStatus
  deleteReferenciaExperienciaStatus: OperationStatus
  updatePdaStatus: OperationStatus
  getLinkReporteStatus: OperationStatus
  updateExperienciaStatus: OperationStatus
  updateExperienciasStatus: OperationStatus
  tieneCVStatus: OperationStatus
  updateDocumentoStatus: OperationStatus
  getApplicantStatus: StatusType
  actualizarFechaCVStatus: StatusType
  visualizacionesCV: VisualizacionesCV | null
  visualizacionesCVStatus: StatusType
}

export const initialState: ApplicantState = {
  applicant: null,
  datosPersonales: null,
  pda: null,
  curriculum: null,
  tieneCV: null,
  pathDescargaCv: null,
  refNamePda: null,
  linkReportePda: null,
  success: false,
  loading: false,
  error: false,
  showSnackbar: defaultShowSnackbar,
  updateDatosPersonalesStatus: defaultOperationStatus,
  updateDatosPersonalesOnbordingStatus: defaultOperationStatus,
  updateImageProfileStatus: defaultOperationStatus,
  updateDatosContactoStatus: defaultOperationStatus,
  sinEstudiosStatus: defaultOperationStatus,
  addOrUpdateEstudioStatus: defaultOperationStatus,
  addEstudiosStatus: status.initial,
  deleteEstudioStatus: defaultOperationStatus,
  deleteReferenciaEstudioStatus: defaultOperationStatus,
  deleteIdiomaStatus: defaultOperationStatus,
  updateReferenciaEstudioStatus: defaultOperationStatus,
  updateCertificadoEstudioStatus: defaultOperationStatus,
  deleteCertificadoEstudioStatus: defaultOperationStatus,
  updateReferenciaLaboralStatus: defaultOperationStatus,
  updateIdiomaStatus: defaultOperationStatus,
  addIdiomasStatus: status.initial,
  deleteDiscapacidadStatus: defaultOperationStatus,
  addDiscapacidadStatus: defaultOperationStatus,
  getDiscapacidadStatus: defaultOperationStatus,
  discapacidadURLToDownload: null,
  addVideoPresentacionStatus: defaultOperationStatus,
  addCvAdjuntoStatus: defaultOperationStatus,
  getCvAdjuntoStatus: defaultOperationStatus,
  getCertificadoAdjuntoStatus: defaultOperationStatus,
  getVideoPresentacionStatus: defaultOperationStatus,
  getVideoPresentacionPreviewStatus: defaultOperationStatus,
  videoPresentacionURL: null,
  videoPresentacionPreviewURL: null,
  deleteVideoPresentacionStatus: defaultOperationStatus,
  deleteCvAdjuntoStatus: defaultOperationStatus,
  cvAdjuntoURL: null,
  certificadoAdjuntoURL: null,
  preferenciaSalarial: null,
  preferenciaSalarialStatus: defaultOperationStatus,
  updatePreferenciaSalarialStatus: defaultOperationStatus,
  deletePreferenciaSalarialStatus: defaultOperationStatus,
  updateObjetivoLaboralStatus: defaultOperationStatus,
  deleteObjetivoLaboralStatus: defaultOperationStatus,
  updateSkillsStatus: defaultOperationStatus,
  replaceSkillsStatus: defaultOperationStatus,
  deleteSkillsStatus: defaultOperationStatus,
  deleteExperienciaStatus: defaultOperationStatus,
  deleteReferenciaExperienciaStatus: defaultOperationStatus,
  updatePdaStatus: defaultOperationStatus,
  getLinkReporteStatus: defaultOperationStatus,
  updateExperienciaStatus: defaultOperationStatus,
  updateExperienciasStatus: defaultOperationStatus,
  primerEmpleoStatus: defaultOperationStatus,
  tieneCVStatus: defaultOperationStatus,
  updateDocumentoStatus: defaultOperationStatus,
  getApplicantStatus: status.initial,
  actualizarFechaCVStatus: status.initial,
  visualizacionesCV: null,
  visualizacionesCVStatus: status.initial,
}
