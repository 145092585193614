import { TagManager } from '@navent-jobs/config'

export const DataLayer = {
  handlerNotificationsTraking: label => {
    /**
     * Debe ejecutarse cuando el usuario selecciona una notificacion.
     */

    return TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'CrossSite',
      eventAction: 'Notificaciones',
      eventLabel: `Click notificacion-${label}`,
    })
  },
}
